import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'

import Layout from '../../components/layout'
import Hero from '../../components/hero-image'
import HeroBar from '../../components/hero-bar'
import ProductOptions from '../../components/product-options'
import ProductFeatures from '../../components/product-features'

import ProductHero from './entrance-door.png'

import WeatherSeals from './feature-dual-weather-seals.png'
// import EgressHinges from './feature-egress-hinges.png'
// import MultiPoint from './feature-multi-point-locking.png'
import ToughenedGlass from './feature-24mm-glazing.png'

const Entrance = () => (
  <Layout>
    <Helmet title="Entrance Doors | Engineered to be solid and secure | Rudd Joinery">
      <meta name="description" content="We design and manufacture beautiful High Performance doorsets. We’re passionate about helping homeowners enjoy premium bespoke products at affordable prices, so you’ll enjoy all the features and benefits of a modern entrance door, including performance weather seals, low level thresholds and performance butt hinges" />      
    </Helmet>
    <Hero heroImage="entrance-door" heroId="hero" nextId="description">
      <h1>Entrance</h1>
      <h2>Engineered to be solid and secure</h2>
    </Hero>
    <div className="section" id="description">
      <div className="wrapper">
        <div
          className="story text-block"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <p>
            The front door is one of the first things you notice about a home,
            so you want to make sure yours says the right things about you and
            your property. We design and manufacture beautiful high performance
            doorsets. We’re passionate about helping homeowners enjoy premium
            bespoke products at affordable prices, so you’ll enjoy all the
            features and benefits of a modern <strong>Entrance</strong> door,
            including performance weather seals, low level thresholds and
            performance butt hinges.
          </p>
          <p>
            Our doors also come with a choice of lock options, a multi-point
            espag locking system with Lever handles or our AV2 Multi-Point Espag
            Lock which replaces the traditional night latch and deadlock offering
            a modern day security solution.
          </p>
          <p>
            A choice of mouldings, panels, ironmongery, glass and paint finish can be chosen from our standard range to compliment your project.
          </p>
          <p>
            <img 
              src={ProductHero} 
              alt="Rudd Joinery | Entrance Door" 
              className="product-hero"
              data-sal-duration="600"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out-bounce"
            />
          </p>
        </div>
      </div>
    </div>
    <ProductFeatures>
      <div className="cta-bar sticky ovalroomblue">
        <div className="expanded">
          <h3>Our entrance door features</h3>
          <ul>
            <li>High performance weather seals</li>
            {/* <li>Multi-point espag Locking</li>
            <li>High quality butt hinges</li> */}
            <li>24mm toughened argon filled double glazing</li>
          </ul>
        </div>
      </div>
      <div className="feature-bar">
        <div className="block c__5">
          <h2>Dual weather seals</h2>
          <p>
            Featuring dual weather seals to further prevent weather ingress in
            exposed locations.
          </p>
        </div>
        <img src={WeatherSeals} alt="Dual weather seals" className="c__2"  />
      </div>
      {/* <div className="feature-bar lightgrey">
        <div className="block c__1">
          <h2>Multi-point espag Locking</h2>
          <p>

          </p>
        </div>
      </div>
      <div className="feature-bar">
        <div className="block c__1">
          <h2>High quality butt hinges</h2>
          <p>

          </p>
        </div>
      </div> */}
      <div className="feature-bar lightgrey">
        <div className="block c__1">
          <h2>24mm toughened double glazing</h2>
          <p>
            High energy efficiency is further increased by argon filled double
            glazing.
          </p>
        </div>
        <img src={ToughenedGlass} alt="24mm toughened double glazing" className="c__5" />
      </div>
      <div className="cta-bar ovalroomblue">
        <div className="expanded">
          <p>
            Our <strong>Entrance</strong> doors can be installed alongside our other products, as all our mouldings are complimented across our product range
          </p>
        </div>
      </div>
    </ProductFeatures>    
    <HeroBar imageName="entrance-door" />
    <div className="section ovalroomblue">
      <div className="wrapper">
        <div className="story text-block">
          <h2 className="heading">Entrance door options</h2>
        </div>
        <ProductOptions ProductName="Entrance Door" />
      </div>
    </div>
    <div className="section lightgrey eco-friendly">
      <div className="wrapper">
        <div
          className="text-block story"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2 className="heading">Entrance door</h2>
          <p>
            Our bespoke <strong>Entrance</strong> door can be
            manufactured to your specific requirements. If you need help, please
            do not hesitate to contact us on <strong>01485 529136</strong> or
            fill out our <Link to="/contact-us#contact-us">contact form</Link>.
          </p>
          <p>
            <Link to="/contact-us" className="cta">
              Contact us
            </Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default Entrance 